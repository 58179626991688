<template>
    <div id="public-holiday" class="col-12">
      <v-row no-gutters class="table-section">
        <v-col cols="12" class="user-page-title main-content">
          <span>View Public Holidays</span>
        </v-col>
        <v-col cols="1" md="0" sm="0"></v-col>
      </v-row>
      <v-row no-gutters id="holiday-table">
        <v-col cols="12" class="main-content">
          <v-data-table
            :headers="headers"
            :items="holidays"
            :items-per-page="pageSize"
            :search="search"
            @pagination="updatePagination"
            no-data-text="No Data"
            :footer-props="footer"
          >
            <template v-slot:top>
              <v-row no-gutters id="table-search">
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="search"
                    placeholder="Search Public Holiday"
                    class="search-table width-search-input"
                    append-icon="fas fa-search"
                    autocomplete="off"
                    rounded
                    outlined
                    filled
                  ></v-text-field>
                </v-col>
                <v-spacer />
                <v-col cols="12" md="2" style="text-align: right;">
                  <button @click="openDialogAddHoliday" class="submit-button">
                    Add New
                  </button>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.no`]="{ index }">
              {{ index + 1 + (currentPage - 1) * pageSize }}
            </template>
            <template v-slot:[`item.start_time`]="{ item }">
              {{ formatHolidayDate(item.start_time) }}
            </template>
            <template v-slot:[`item.end_time`]="{ item }">
              {{ formatHolidayDate(item.end_time) }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn :ripple="false" icon class="mr-3">
                <v-img
                  alt="Holiday"
                  class="shrink mr-2"
                  contain
                  src="../../assets/tables/Button_edit.svg"
                  transition="scale-transition"
                  @click="openDialogEditHoliday(item)"
                />
              </v-btn>
              <v-btn :ripple="false" icon>
                <v-img
                  alt="Holiday"
                  class="shrink mr-2"
                  contain
                  src="../../assets/tables/Button_delete.svg"
                  transition="scale-transition"
                  @click="
                    openConfirmDialog(
                      item,
                      deleteHoliday,
                      'Are you sure you want to delete this holiday?'
                    )
                  "
                />
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" md="0" sm="0" />
      </v-row>
      <v-dialog
        v-model="dialogAddHoliday"
        persistent
        content-class="user-edit-dialog"
        max-width="500"
      >
        <v-card>
          <v-card-title>Add Public Holiday</v-card-title>
          <v-card-text>
            <v-form
              ref="addForm"
              v-model="valid"
              @submit.prevent="submitAdd"
              autocomplete="off"
            >
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card outlined tile style="border: none !important">
                    <v-card-text
                    >
                      <v-row no-gutters>
                        <v-col cols="12">
                          <p class="label">Start Date</p>
                          <v-menu
                            v-model="menuAddStartDate"
                            :close-on-content-click="false"
                            :nudge-right="30"
                            :nudge-top="30"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="start_date"
                                prepend-icon="fas fa-calendar-alt"
                                placeholder="Start Date"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="startDateRules"
                                class="icon-position"
                              ></v-text-field>
                            </template>
                            <v-date-picker 
                              v-model="start_date" 
                              @input="menuAddStartDate = false"
                              :min="NOW_DATE().format(DATE_FORMAT_SCHEDULE)"
                              no-title
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card outlined tile style="border: none !important">
                    <v-card-text
                    >
                      <v-row no-gutters>
                        <v-col cols="12">
                          <p class="label">End Date</p>
                          <v-menu
                            v-model="menuAddEndDate"
                            :close-on-content-click="false"
                            :nudge-right="30"
                            :nudge-top="30"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="end_date"
                                prepend-icon="fas fa-calendar-alt"
                                placeholder="End Date"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="endDateRules"
                                class="icon-position"
                              ></v-text-field>
                            </template>
                            <v-date-picker 
                              v-model="end_date" 
                              @input="menuAddEndDate = false"
                              :min="NOW_DATE().format(DATE_FORMAT_SCHEDULE)"
                              no-title
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" class="text-right">
                  <button
                    class="btn btn-secondary btn-custom"
                    type="button"
                    :ripple="false"
                    @click="closeDialogAddHoliday"
                  >
                    Cancel
                  </button>
                  <button
                    class="ml-4 btn btn-primary btn-custom"
                    type="button"
                    :ripple="false"
                    @click="revertAdd"
                  >
                    Revert
                  </button>
                  <button
                    class="ml-4 btn btn-primary btn-custom"
                    :disabled="!valid"
                    :ripple="false"
                    type="submit"
                  >
                    Add
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogEditHoliday"
        persistent
        content-class="user-edit-dialog"
        max-width="500"
      >
        <v-card>
          <v-card-title>Edit Holiday</v-card-title>
          <v-card-text>
            <v-form
              ref="editForm"
              v-model="valid"
              @submit.prevent="submitEdit"
              autocomplete="off"
            >
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card outlined tile style="border: none !important">
                    <v-card-text
                    >
                      <v-row no-gutters>
                        <v-col cols="12">
                          <p class="label">Start Date</p>
                          <v-menu
                            v-model="menuEditStartDate"
                            :close-on-content-click="false"
                            :nudge-right="30"
                            :nudge-top="30"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editStartDate"
                                prepend-icon="fas fa-calendar-alt"
                                placeholder="Start Date"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="startDateRules"
                                class="icon-position"
                              ></v-text-field>
                            </template>
                            <v-date-picker 
                              v-model="editStartDate" 
                              @input="menuEditStartDate = false"
                              :min="NOW_DATE().format(DATE_FORMAT_SCHEDULE)"
                              no-title
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card outlined tile style="border: none !important">
                    <v-card-text
                    >
                      <v-row no-gutters>
                        <v-col cols="12">
                          <p class="label">End Date</p>
                          <v-menu
                            v-model="menuEditEndDate"
                            :close-on-content-click="false"
                            :nudge-right="30"
                            :nudge-top="30"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editEndDate"
                                prepend-icon="fas fa-calendar-alt"
                                placeholder="End Date"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="endDateRules"
                                class="icon-position"
                              ></v-text-field>
                            </template>
                            <v-date-picker 
                              v-model="editEndDate" 
                              @input="menuEditEndDate = false"
                              :min="NOW_DATE().format(DATE_FORMAT_SCHEDULE)"
                              no-title
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" class="text-right">
                  <button
                    class="btn btn-secondary btn-custom"
                    type="button"
                    :ripple="false"
                    @click="closeDialogEditHoliday"
                  >
                    Cancel
                  </button>
                  <button
                    class="ml-4 btn btn-primary btn-custom"
                    type="button"
                    :ripple="false"
                    @click="revertEdit"
                  >
                    Revert
                  </button>
                  <button
                    class="ml-4 btn btn-primary btn-custom"
                    :disabled="!valid"
                    :ripple="false"
                    type="submit"
                  >
                    Save
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmDialog"
        persistent
        max-width="700px"
        content-class="user-edit-dialog"
      >
        <v-card>
          <v-card-title>Confirm</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12 pt-4">
                <span>{{ dialogMessage }}</span>
              </v-col>
              <v-col cols="12 pt-4" class="text-right">
                <button
                  class="btn btn-secondary btn-custom"
                  type="button"
                  :ripple="false"
                  @click="closeConfirmDialog"
                >
                  CANCEL
                </button>
                <button
                  class="ml-4 btn btn-primary btn-custom"
                  type="button"
                  :ripple="false"
                  @click="dialogAction(holidayItem)"
                >
                  YES
                </button>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="messageDialog"
        max-width="700"
        content-class="profile-confirm-dialog"
      >
        <v-card id="profile-confirm">
          <v-card-title>{{ apiTitle }}</v-card-title>
          <v-card-text
            ><v-row no-gutters>
              <v-col cols="12 pt-4" style="padding-top: 6px">
                <div v-html="apiMessage"></div>
              </v-col>
              <v-col cols="12 pt-4" class="text-right">
                <button
                  class="btn btn-primary btn-custom"
                  :ripple="false"
                  @click="closeMessage"
                >
                  OK
                </button>
              </v-col>
            </v-row></v-card-text
          >
        </v-card>
      </v-dialog>
    </div>
  </template>
  <style lang="scss" src="../../assets/css/public-holiday.scss"></style>
  <script>
  import { footerProps } from "../../constants";
  import { holidayService, userProfileService } from "../../services";
  import moment from "moment";
  export default {
    components: {},
    data() {
      return {
        search: "",
        messageDialog: false,
        apiMessage: "",
        apiTitle: "",
        total: 0,
        headers: [
          {
            text: "No",
            value: "no",
            sortable: false,
            filterable: false,
          },
          { text: "Start Date", value: "start_time" },
          { text: "End Date", value: "end_time" },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            filterable: false,
          },
        ],
        pageSize: 10,
        currentPage: 1,
        valid: false,
        startDateRules: [
          (v) => !!v || "Start Date is required",
        ],
        endDateRules: [
          (v) => !!v || "End Date is required",
        ],
        dialogAddHoliday: false,
        dialogEditHoliday: false,
        footer: {},
        confirmDialog: false,
        dialogAction: () => null,
        dialogMessage: "",
        start_date: "",
        end_date: "",
        editStartDate: "",
        editEndDate: "",
        menuAddStartDate: false,
        menuAddEndDate: false,
        menuEditStartDate: false,
        menuEditEndDate: false,
        holidays: [],
        holidayItem: {},
        holidayInfo: null,
        interval_browser: null,
      };
    },
    mounted(){
    this.interval_browser = setInterval(() => {
      userProfileService.getCurrentUserProfile();
    }, 5000);
    },
    destroyed() {
      clearInterval(this.interval_browser);
    },
    created() {
      this.footer = Object.assign({}, this.footer, footerProps);
      this.getListHolidays();
    },
    methods: {
      updatePagination(pagination) {
        this.currentPage = pagination.page;
        this.pageSize = pagination.itemsPerPage;
        this.$set(
          this.footer,
          "pageText",
          `${this.currentPage} of ${this.total}`
        );
      },
      getListHolidays() {
        holidayService.getListHolidays().then((res) => {
          if (res?.data?.data?.length) {
            const data = res.data.data;
            this.total = Math.ceil((res.data.data.length || 0) / this.pageSize);
            this.holidays = [...data];
          }
        });
      },
      submitAdd() {
        if (this.$refs.addForm.validate()) {
          const data = this.prepareHolidayData(
            this.start_date,
            this.end_date,
          );
          if(this.checkValidDate(data)){
            holidayService
            .storeHoliday(data)
            .then((res) => {
              if (res.status !== 200) throw res;
              this.getListHolidays();
              this.revertHoliday();
              this.closeDialogAddHoliday();
              this.apiMessage = "Holiday has been successfully added";
              this.apiTitle = "Success";
              this.messageDialog = true;
            })
            .catch((err) => {
              let message = "Holiday cannot be added";
              if (err?.response?.data?.message) {
                message = err.response.data.message;
              }
              this.apiMessage = message;
              this.apiTitle = "Error";
              this.messageDialog = true;
            });
          }
        }
      },
      revertHoliday() {
        this.$refs.addForm.reset();
      },
      prepareHolidayData(startDate, endDate) {
        const data = {
          start_time: moment(startDate).startOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
          end_time: moment(endDate).endOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
        };
        return data;
      },
      openDialogAddHoliday() {
        this.dialogAddHoliday = true;
      },
      openDialogEditHoliday(item) {
        if (item?.id) {
          holidayService
            .getHoliday(item.id)
            .then((res) => {
              if (res?.data?.data) {
                this.setHolidayInfo(res.data.data);
                this.dialogEditHoliday = true;
              }
            })
            .catch();
        }
      },
      closeDialogAddHoliday() {
        this.dialogAddHoliday = false;
      },
      closeDialogEditHoliday() {
        this.setHolidayInfo(null);
        this.dialogEditHoliday = false;
      },
      openConfirmDialog(item, action, message) {
        this.holidayItem = Object.assign({}, this.holidayItem, item);
        this.dialogAction = action;
        this.dialogMessage = message;
        this.confirmDialog = true;
      },
      closeConfirmDialog() {
        this.holidayItem = Object.assign({}, this.holidayItem);
        this.confirmDialog = false;
        this.dialogAction = () => null;
        this.dialogMessage = "";
      },
      revertEdit() {
        this.setHolidayInfo(this.holidayInfo);
      },
      revertAdd() {
        this.start_date = "";
        this.end_date = "";
      },
      setHolidayInfo(data) {
        if (data) {
          this.editStartDate = moment(data.start_time).format(this.DATE_FORMAT_SCHEDULE);
          this.editEndDate = moment(data.end_time).format(this.DATE_FORMAT_SCHEDULE);
          this.holidayInfo = data;
        } else {
          this.editStartDate = "";
          this.editEndDate = "";
          this.holidayInfo = null;
        }
      },
      submitEdit() {
        const data = this.prepareHolidayData(
          this.editStartDate,
          this.editEndDate,
        );
        if(this.checkValidDate(data)){
          holidayService
          .updateHoliday(this.holidayInfo.id, data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.closeDialogEditHoliday();
            this.apiMessage = "Holiday's information has been successfully updated";
            this.apiTitle = "Success";
            this.messageDialog = true;
            this.getListHolidays();
          })
          .catch((err) => {
            let message = "Holiday's information cannot be updated";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
        }
      },
      deleteHoliday(item) {
        if (item?.id) {
          holidayService
            .deleteHoliday(item.id)
            .then((res) => {
              if (res.status !== 200 && res.status !== 204) throw res;
              this.closeConfirmDialog();
              this.getListHolidays();
              this.apiMessage = "Holiday has been successfully deleted";
              this.apiTitle = "Success";
              this.messageDialog = true;
            })
            .catch((err) => {
              let message = "Holiday cannot be deleted";
              if (err?.response?.data?.message) {
                message = err.response.data.message;
              }
              this.apiMessage = message;
              this.apiTitle = "Error";
              this.messageDialog = true;
            });
        }
      },
      closeMessage() {
        this.messageDialog = false;
      },
      formatHolidayDate(value) {
        if (value) {
          return moment(String(value)).format(this.DATE_FORMAT_SCHEDULE)
        }
      },
      parseMomentDate(value){
        return moment(value)
      },
      checkValidDate(item){
        let startDate = moment(item.start_time);
        let endDate = moment(item.end_time);
        if (startDate > endDate) {
          this.apiMessage = "End date must be greater than or equal to start date.";
          this.apiTitle = "Error";
          this.messageDialog = true;
          return false;
        }
        return true;
      },
    },
  };
  </script>
  